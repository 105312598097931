var $ = require("jquery");
var Cookies = require("js-cookie");

jQuery(function () {
  if (Cookies.get("cookie_aceptance") !== "true") {
    const displayCookieAlert = function () {
      $("body").append(
        $('<div id="cookiebar">' + CookieAcceptor.text + "</div>"),
      );
    };

    displayCookieAlert();
  }

  $("#acceptcookies").click(function () {
    const acceptCookies = function () {
      Cookies.set("cookie_aceptance", "true", { expires: 365, path: "/" });
      $("#cookiebar").hide();
    };
    acceptCookies();
  });

  $("#moreinfocookies").click(function () {
    window.location = CookieAcceptor.url;
  });
});
